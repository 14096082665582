import { ApiParameters, ClientBase, CollectionSuccessResponse, CompanyData, CompanySaveData, CompanyInfo, CompanyVerificationSaveData, SuccessResponse } from './client-base';

export class CompanyClient extends ClientBase {
  private endpoint = '/api/companies';

  constructor() {
    super();
  }

  public createCompany(data: CompanySaveData) {
    return new Promise<SuccessResponse<CompanySaveData>>((resolve, reject) => {
      this.getCurrentAuthToken()
        .then(token => {
          fetch(`${this.baseUrl}${this.endpoint}/`, {
            method: 'POST',
            headers: {
              'Content-type': 'application/json',
              'Strapi-Response-Format': 'v4',
              'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify(data),
          })
            .then(response => resolve(this.processResponse(response)))
            .catch(error => reject(error));
        })
        .catch(() => {
          window.location.replace('/logout');
        }); // The jwt is missing, invalid, or corrupted, log the user out.);
    });
  }

  public getCompany(companyId: string | number, parameters?: ApiParameters) {
    this.checkForDocumentId(companyId, 'CompanyClient', 'getCompany');
    return new Promise<CompanyData>((resolve, reject) => {
      let query = this.stringifyParameters(parameters);
      this.getCurrentAuthToken()
        .then(token => {
          fetch(`${this.baseUrl}${this.endpoint}/${companyId}?${query}`, {
            method: 'GET',
            headers: {
              'Content-type': 'application/json',
              'Strapi-Response-Format': 'v4',
              'Authorization': `Bearer ${token}`,
            },
          })
            .then(response => resolve(this.processResponse(response)))
            .catch(error => reject(error));
        })
        .catch(() => {
          window.location.replace('/logout');
        }); // The jwt is missing, invalid, or corrupted, log the user out.);
    });
  }

  public getCompanies(parameters?: ApiParameters) {
    return new Promise<CollectionSuccessResponse<CompanyInfo>>((resolve, reject) => {
      let query = this.stringifyParameters(parameters);
      this.getCurrentAuthToken()
        .then(token => {
          fetch(`${this.baseUrl}${this.endpoint}?${query}`, {
            method: 'GET',
            headers: {
              'Content-type': 'application/json',
              'Strapi-Response-Format': 'v4',
              'Authorization': `Bearer ${token}`,
            },
          })
            .then(response => resolve(this.processResponse(response)))
            .catch(error => reject(error));
        })
        .catch(() => {
          window.location.replace('/logout');
        }); // The jwt is missing, invalid, or corrupted, log the user out.);
    });
  }

  public securityCheck(companyId: string | number) {
    this.checkForDocumentId(companyId, 'CompanyClient', 'securityCheck');
    return new Promise<SecurityCheckResponse>((resolve, reject) => {
      this.getCurrentAuthToken()
        .then(token => {
          fetch(`${this.baseUrl}${this.endpoint}/security/${companyId}`, {
            method: 'GET',
            headers: {
              'Content-type': 'application/json',
              'Strapi-Response-Format': 'v4',
              'Authorization': `Bearer ${token}`,
            },
          })
            .then(response => resolve(this.processResponse(response)))
            .catch(error => reject(error));
        })
        .catch(() => {
          window.location.replace('/logout');
        }); // The jwt is missing, invalid, or corrupted, log the user out.);
    });
  }

  public updateCompany(companyId: string | number, data: CompanySaveData) {
    this.checkForDocumentId(companyId, 'CompanyClient', 'updateCompany');
    return new Promise<SuccessResponse<CompanySaveData>>((resolve, reject) => {
      this.getCurrentAuthToken()
        .then(token => {
          fetch(`${this.baseUrl}${this.endpoint}/${companyId}`, {
            method: 'PUT',
            headers: {
              'Content-type': 'application/json',
              'Strapi-Response-Format': 'v4',
              'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify(data),
          })
            .then(response => resolve(this.processResponse(response)))
            .catch(error => reject(error));
        })
        .catch(() => {
          window.location.replace('/logout');
        }); // The jwt is missing, invalid, or corrupted, log the user out.);
    });
  }

  public verifyCompany(companyId: string | number, data: CompanyVerificationSaveData) {
    this.checkForDocumentId(companyId, 'CompanyClient', 'verifyCompany');
    return new Promise<any>((resolve, reject) => {
      this.getCurrentAuthToken()
        .then(token => {
          fetch(`${this.baseUrl}${this.endpoint}/verify/${companyId}`, {
            method: 'POST',
            headers: {
              'Content-type': 'application/json',
              'Strapi-Response-Format': 'v4',
              'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify(data),
          })
            .then(response => resolve(this.processResponse(response)))
            .catch(error => reject(error));
        })
        .catch(() => {
          window.location.replace('/logout');
        }); // The jwt is missing, invalid, or corrupted, log the user out.);
    });
  }

  public submitForPublication(companyId: string | number, notes: string) {
    this.checkForDocumentId(companyId, 'CompanyClient', 'submitForPublication');
    const data = { Notes: notes };
    return new Promise<any>((resolve, reject) => {
      this.getCurrentAuthToken()
        .then(token => {
          fetch(`${this.baseUrl}${this.endpoint}/submit-for-publication/${companyId}`, {
            method: 'POST',
            headers: {
              'Content-type': 'application/json',
              'Strapi-Response-Format': 'v4',
              'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify(data),
          })
            .then(response => resolve(this.processResponse(response)))
            .catch(error => reject(error));
        })
        .catch(() => {
          window.location.replace('/logout');
        }); // The jwt is missing, invalid, or corrupted, log the user out.);
    });
  }

  public publicationRejected(companyId: string | number, notes: string) {
    this.checkForDocumentId(companyId, 'CompanyClient', 'publicationRejected');
    const data = { Notes: notes };
    return new Promise<any>((resolve, reject) => {
      this.getCurrentAuthToken()
        .then(token => {
          fetch(`${this.baseUrl}${this.endpoint}/publication-rejected/${companyId}`, {
            method: 'POST',
            headers: {
              'Content-type': 'application/json',
              'Strapi-Response-Format': 'v4',
              'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify(data),
          })
            .then(response => resolve(this.processResponse(response)))
            .catch(error => reject(error));
        })
        .catch(() => {
          window.location.replace('/logout');
        }); // The jwt is missing, invalid, or corrupted, log the user out.);
    });
  }

  public publicationComplete(companyId: string | number) {
    this.checkForDocumentId(companyId, 'CompanyClient', 'publicationComplete');
    return new Promise<any>((resolve, reject) => {
      this.getCurrentAuthToken()
        .then(token => {
          fetch(`${this.baseUrl}${this.endpoint}/publication-complete/${companyId}`, {
            method: 'POST',
            headers: {
              'Content-type': 'application/json',
              'Strapi-Response-Format': 'v4',
              'Authorization': `Bearer ${token}`,
            },
          })
            .then(response => resolve(this.processResponse(response)))
            .catch(error => reject(error));
        })
        .catch(() => {
          window.location.replace('/logout');
        }); // The jwt is missing, invalid, or corrupted, log the user out.);
    });
  }

  public getDocumentId(companyId: number) {
    return new Promise<TranslateResponse>((resolve, reject) => {
      this.getCurrentAuthToken()
        .then(token => {
          fetch(`${this.baseUrl}${this.endpoint}/translate/${companyId}`, {
            method: 'GET',
            headers: {
              'Content-type': 'application/json',
              'Strapi-Response-Format': 'v4',
              'Authorization': `Bearer ${token}`,
            },
          })
            .then(response => resolve(this.processResponse(response)))
            .catch(error => reject(error));
        })
        .catch(() => {
          window.location.replace('/logout');
        }); // The jwt is missing, invalid, or corrupted, log the user out.);
    });
  }
}
export interface SecurityCheckResponse {
  canManageCompanyDetails: boolean;
  canViewCompanyDetails: boolean;
  canManageCompanyStaff: boolean;
  isEmbarkEditor: boolean;
  isCompanyAdmin: boolean;
  isAccountManager: boolean;
}

export interface TranslateResponse {
  data: { documentId: string };
}
