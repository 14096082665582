import { AddressAttributes, DataResponse } from '../components';
import {
  ChartbrewReportAttributes,
  CompanyAlternateNameAttributes,
  CompanyInfo,
  CompanyStatusInfo,
  CompanySubTypeAttributes,
  CompanySubTypeItem,
  CompanyTypeQuestionAttributes,
  CountryAttributes,
  CountrySubdivisionAttributes,
  EmailAddressAttributes,
  EventSpaceAttributes,
  EventSpaceLayoutAttributes,
  FileAttachmentAttributes,
  FuzzySearchResult,
  GeographySearchItemAttributes,
  GeographySearchItemTreeNode,
  GeographySearchItemResult,
  IngredientAttributes,
  LanguageAttributes,
  LayoutTypeAttributes,
  MediaCategoryAttributes,
  PersonInfo,
  PersonAtCompanyInfo,
  PhoneNumberAttributes,
  PrimaryContactTypeAttributes,
  SavedFavoriteListItem,
  SearchFilterAttributes,
  SponsorshipAttributes,
  SponsorshipFlatAttributes,
  SponsorshipLocationAttributes,
  SponsorshipStatusAttributes,
  UsefulLinkAttributes,
  VRLinkAttributes,
  PublicationNoteAttributes,
} from './clients/client-base';

export class ComparerService {
  // Sort Address By Rank
  compareAddress(a: DataResponse<AddressAttributes>, b: DataResponse<AddressAttributes>) {
    return (
      Number(b.attributes.IsPrimary) - Number(a.attributes.IsPrimary) ||
      (a.attributes.address_type?.data?.attributes?.Rank ?? 0) - (b.attributes.address_type?.data?.attributes?.Rank ?? 0) ||
      a.id - b.id
    );
  }

  compareChartbrewReports(a: DataResponse<ChartbrewReportAttributes>, b: DataResponse<ChartbrewReportAttributes>) {
    return a.attributes.Rank - b.attributes.Rank || a.id - b.id;
  }

  compareCompanies(a: DataResponse<CompanyInfo>, b: DataResponse<CompanyInfo>) {
    return (a.attributes?.Name ?? '').localeCompare(b.attributes?.Name ?? '') || a.id - b.id;
  }

  compareCompanyAlternateNames(a: DataResponse<CompanyAlternateNameAttributes>, b: DataResponse<CompanyAlternateNameAttributes>) {
    return (a.attributes?.AlternateName ?? '').localeCompare(b.attributes?.AlternateName ?? '') || a.id - b.id;
  }

  compareCompanyStatus(a: DataResponse<CompanyStatusInfo>, b: DataResponse<CompanyStatusInfo>) {
    return (a.attributes?.DisplayName ?? '').localeCompare(b.attributes?.DisplayName ?? '');
  }

  compareCompanySubTypeItem(a: CompanySubTypeItem, b: CompanySubTypeItem) {
    return (a.Name ?? '').localeCompare(b.Name ?? '');
  }

  compareCompanySubType(a: DataResponse<CompanySubTypeAttributes>, b: DataResponse<CompanySubTypeAttributes>) {
    return (a.attributes.Name ?? '').localeCompare(b.attributes.Name ?? '');
  }

  // Sort CompanyTypeQuestion By Question Rank
  compareCompanyTypeQuestionByQuestionRank(a: DataResponse<CompanyTypeQuestionAttributes>, b: DataResponse<CompanyTypeQuestionAttributes>) {
    return Number(a.attributes.question.data.attributes.Rank) - Number(b.attributes.question.data.attributes.Rank) || a.id - b.id;
  }

  // Sort CompanyTypeQuestion By Question Rank
  compareCompanyTypeQuestionByQuestionRankAndSubcategory(a: DataResponse<CompanyTypeQuestionAttributes>, b: DataResponse<CompanyTypeQuestionAttributes>) {
    return (
      Number(a.attributes.question.data.attributes.Rank) - Number(b.attributes.question.data.attributes.Rank) ||
      (a.attributes.question.data.attributes.Subcategory ?? '').localeCompare(b.attributes.question.data.attributes.Subcategory ?? '') ||
      a.id - b.id
    );
  }

  compareCountry(a: DataResponse<CountryAttributes>, b: DataResponse<CountryAttributes>) {
    return (a.attributes.SearchableName ?? '').localeCompare(b.attributes.SearchableName ?? '');
  }

  compareCountrySubdivision(a: DataResponse<CountrySubdivisionAttributes>, b: DataResponse<CountrySubdivisionAttributes>) {
    return (a.attributes.Name ?? '').localeCompare(b.attributes.Name ?? '');
  }

  // Sort Email By Rank
  compareEmailType(a: DataResponse<EmailAddressAttributes>, b: DataResponse<EmailAddressAttributes>) {
    return (
      Number(b.attributes.IsPrimary) - Number(a.attributes.IsPrimary) ||
      (a.attributes.email_address_type?.data?.attributes?.Rank ?? 0) - (b.attributes.email_address_type?.data?.attributes?.Rank ?? 0) ||
      a.id - b.id
    );
  }

  compareEventSpace(a: DataResponse<EventSpaceAttributes>, b: DataResponse<EventSpaceAttributes>) {
    return (a.attributes?.Name ?? '').localeCompare(b.attributes?.Name ?? '');
  }

  compareEventSpaceLayout(a: DataResponse<EventSpaceLayoutAttributes>, b: DataResponse<EventSpaceLayoutAttributes>) {
    return (a.attributes?.LayoutType?.data?.attributes?.Name ?? '').localeCompare(b.attributes?.LayoutType?.data?.attributes?.Name ?? '');
  }

  // Sort CompanyTypeQuestion By Question Rank
  compareFileAttachment(a: DataResponse<FileAttachmentAttributes>, b: DataResponse<FileAttachmentAttributes>) {
    const aSortFiled = (a.attributes.caption ?? '').length > 0 ? a.attributes.caption : a.attributes.name;
    const bSortFiled = (b.attributes.caption ?? '').length > 0 ? b.attributes.caption : b.attributes.name;
    return aSortFiled.localeCompare(bSortFiled) || a.id - b.id;
  }

  compareFuzzySearchResult(a: FuzzySearchResult, b: FuzzySearchResult) {
    return (b.Type == 'Company' ? 1 : 0) - (a.Type == 'Company' ? 1 : 0) || a.score - b.score;
  }

  compareGeographySearchItem(a: DataResponse<GeographySearchItemAttributes>, b: DataResponse<GeographySearchItemAttributes>) {
    return (a.attributes?.SearchableName ?? '').localeCompare(b.attributes?.SearchableName ?? '');
  }

  compareGeographySearchItemResults(a: GeographySearchItemResult, b: GeographySearchItemResult) {
    return b.Score - a.Score || a.SearchableName.localeCompare(b.SearchableName) || b.id - a.id;
  }

  compareGeographySearchItemWeightName(a: DataResponse<GeographySearchItemAttributes>, b: DataResponse<GeographySearchItemAttributes>) {
    return Number(b.attributes.Weight ?? 1) - Number(a.attributes.Weight ?? 1) || (a.attributes?.SearchableName ?? '').localeCompare(b.attributes?.SearchableName ?? '');
  }

  compareGeographySearchItemTreeNode(a: GeographySearchItemTreeNode, b: GeographySearchItemTreeNode) {
    return (a.geographySearchItem?.attributes?.SearchableName ?? '').localeCompare(b.geographySearchItem?.attributes?.SearchableName ?? '');
  }

  compareIngredient(a: DataResponse<IngredientAttributes>, b: DataResponse<IngredientAttributes>) {
    return (a.attributes.Name ?? '').localeCompare(b.attributes.Name ?? '');
  }

  compareLanguagesSpoken(a: DataResponse<LanguageAttributes>, b: DataResponse<LanguageAttributes>) {
    return (a.attributes.SearchableName ?? '').localeCompare(b.attributes.SearchableName ?? '');
  }

  compareLayoutType(a: DataResponse<LayoutTypeAttributes>, b: DataResponse<LayoutTypeAttributes>) {
    return (a.attributes.Name ?? '').localeCompare(b.attributes.Name ?? '');
  }

  compareMediaCategories(a: DataResponse<MediaCategoryAttributes>, b: DataResponse<MediaCategoryAttributes>) {
    var rankA = a.attributes.Name;
    var rankB = b.attributes.Name;
    return rankA < rankB ? -1 : rankA > rankB ? 1 : 0;
  }

  // Sort PersonAtCompany By Company and Job Title
  comparePACs(a: DataResponse<PersonAtCompanyInfo>, b: DataResponse<PersonAtCompanyInfo>) {
    return (
      (a.attributes.Company?.data?.attributes?.Name ?? '').localeCompare(b.attributes.Company?.data?.attributes?.Name ?? '') ||
      (a.attributes.JobTitle ?? '').localeCompare(b.attributes.JobTitle ?? '')
    );
  }

  // Sort Email By Rank
  comparePhoneType(a: DataResponse<PhoneNumberAttributes>, b: DataResponse<PhoneNumberAttributes>) {
    return (
      Number(b.attributes.IsPrimary) - Number(a.attributes.IsPrimary) ||
      (a.attributes.phone_number_type?.data?.attributes?.Rank ?? 0) - (b.attributes.phone_number_type?.data?.attributes?.Rank ?? 0) ||
      a.id - b.id
    );
  }

  compareSavedFavoriteListItems(a: SavedFavoriteListItem, b: SavedFavoriteListItem) {
    return (a.Name ?? '').localeCompare(b.Name ?? '') || a.id - b.id;
  }

  comparePrimaryContactTypes(a: DataResponse<PrimaryContactTypeAttributes>, b: DataResponse<PrimaryContactTypeAttributes>) {
    return (a.attributes.Name ?? '').localeCompare(b.attributes.Name ?? '');
  }

  compareSearchFilters(a: SearchFilterAttributes, b: SearchFilterAttributes) {
    return a.FilterRank - b.FilterRank || a.id - b.id;
  }

  compareSponsorships(a: DataResponse<SponsorshipAttributes>, b: DataResponse<SponsorshipAttributes>) {
    const startDateA = a.attributes.StartDate ? new Date(a.attributes.StartDate) : new Date();
    const endDateA = a.attributes.EndDate ? new Date(a.attributes.EndDate) : new Date();
    const startDateB = b.attributes.StartDate ? new Date(b.attributes.StartDate) : new Date();
    const endDateB = b.attributes.EndDate ? new Date(b.attributes.EndDate) : new Date();
    return (
      startDateA.getTime() - startDateB.getTime() || endDateA.getTime() - endDateB.getTime() || (a.attributes.Name ?? '').localeCompare(b.attributes.Name ?? '') || a.id - b.id
    );
  }

  compareSponsorshipsFlat(a: SponsorshipFlatAttributes, b: SponsorshipFlatAttributes) {
    const startDateA = a.StartDate ? new Date(a.StartDate) : new Date();
    const endDateA = a.EndDate ? new Date(a.EndDate) : new Date();
    const startDateB = b.StartDate ? new Date(b.StartDate) : new Date();
    const endDateB = b.EndDate ? new Date(b.EndDate) : new Date();
    return startDateA.getTime() - startDateB.getTime() || endDateA.getTime() - endDateB.getTime() || (a.Name ?? '').localeCompare(b.Name ?? '') || a.id - b.id;
  }

  compareSponsorshipLocations(a: DataResponse<SponsorshipLocationAttributes>, b: DataResponse<SponsorshipLocationAttributes>) {
    return (a.attributes.Name ?? '').localeCompare(b.attributes.Name ?? '');
  }

  compareSponsorshipStatuses(a: DataResponse<SponsorshipStatusAttributes>, b: DataResponse<SponsorshipStatusAttributes>) {
    return a.attributes.Rank - b.attributes.Rank || a.id - b.id;
  }

  // Sort PersonAtCompany By Last Name, First Name, and Job Title
  compareTeamMember(a: DataResponse<PersonAtCompanyInfo>, b: DataResponse<PersonAtCompanyInfo>) {
    return (
      (a.attributes.Person?.data?.attributes?.LastName ?? '').localeCompare(b.attributes.Person?.data?.attributes?.LastName ?? '') ||
      (a.attributes.Person?.data?.attributes?.FirstName ?? '').localeCompare(b.attributes.Person?.data?.attributes?.FirstName ?? '') ||
      (a.attributes.JobTitle ?? '').localeCompare(b.attributes.JobTitle ?? '')
    );
  }

  // Sort Person By Last Name, First Name, Middle Name, and Id
  comparePerson(a: DataResponse<PersonInfo>, b: DataResponse<PersonInfo>) {
    return (
      (a?.attributes?.LastName ?? '').localeCompare(b.attributes?.LastName ?? '') ||
      (a.attributes?.FirstName ?? '').localeCompare(b.attributes?.FirstName ?? '') ||
      (a.attributes?.MiddleName ?? '').localeCompare(b.attributes?.MiddleName ?? '') ||
      a.id - b.id
    );
  }

  compareUsefulLinks(a: DataResponse<UsefulLinkAttributes>, b: DataResponse<UsefulLinkAttributes>) {
    return (
      a.attributes.Rank - b.attributes.Rank ||
      (a.attributes.DisplayText ?? '').localeCompare(b.attributes.DisplayText ?? '') ||
      (a.attributes.URL ?? '').localeCompare(b.attributes.URL ?? '')
    );
  }

  compareVRLinks(a: DataResponse<VRLinkAttributes>, b: DataResponse<VRLinkAttributes>) {
    return (
      a.attributes.Rank - b.attributes.Rank || (a.attributes.Name ?? '').localeCompare(b.attributes.Name ?? '') || (a.attributes.URL ?? '').localeCompare(b.attributes.URL ?? '')
    );
  }

  comparePublicationNotes(a: DataResponse<PublicationNoteAttributes>, b: DataResponse<PublicationNoteAttributes>) {
    const dateA = new Date(a.attributes.createdAt);
    const dateB = new Date(b.attributes.createdAt);
    return dateB.getTime() - dateA.getTime(); // return newest first
  }
}
